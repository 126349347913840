import React from "react";
import "./Footer.scss";

const Footer:React.FC = () =>{
    return (
         <div className="Footer_wrapper">
            <div className="Footer_title">Win-Win Education Co.</div>
        </div>
    )
}

export default Footer;